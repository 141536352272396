.loginCard {
  width: 100%;
  height: 100%;
}

.loginCardGroup {
  width: 100%;
  height: 100%;
}

.loginCardContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.loginImageContainer {
  margin: 0;
  padding: 0;
  height: 100%;
}

.loginImage {
  height: 100%;
  resize: "center";
}

.logoSmall {
  max-width: 100px;
  max-height: 100px;
  margin: auto;
}

.logo {
  margin: auto;
}

.fa-apple {
  color: white;
  background-color: gray;
}

@media only screen and (max-width: 576px) {
  .loginImageContainer {
    display: none;
  }
}
